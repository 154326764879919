/* Pagination container */
.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px; /* Adjust as needed */
  max-width: 100%; /* Adjust as needed */
  width: 60%;
  background-color: white;
  padding: 8px;
  margin: 0 auto;
}

/* Page link styles */
.pageLink {
  color: black;
  font-size: 14px;
  font-weight: normal;
}

/* Previous button styles */
/* .previousButton {
  
} */

/* Next button styles */
/* .nextButton {

} */

/* Previous and Next button link styles */
.previousLink,
.nextLink {
  color: #777;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Active page link styles */
.activeLink {
  background-color: #007bff;
  color: white;
  width: 36px; /* Adjust as needed */
  height: 36px; /* Adjust as needed */
  padding: 10px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px; /* Adjust as needed */
}
