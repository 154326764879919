.table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
}

.table th,
.table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table th {
  background-color: white;
}

.table tbody tr:nth-child(1) {
  background-color: white;
  text-align: center;
  width: 50%;
}

.table tbody tr {
  background-color: #f2f2f2;
}
